<template src='./ContactForm.html'></template>
<style scoped lang='scss' src='./ContactForm.scss'></style>

<script>
import Vue from 'vue';
import {VueRecaptcha} from 'vue-recaptcha';
import axios from 'axios';
import config from "../../js/config";
import VueGtag from 'vue-gtag';

Vue.use(VueGtag, {
  config: {
    id: 'G-YEBJBLGKC7',
  }
});

export default {
  name: "ContactForm",
  components: {
    VueRecaptcha
  },
  data: () => ({
    formIsFilled: false,
    errors: [],
    company: null,
    industry: null,
    country: null,
    first_name: null,
    last_name: null,
    phone: null,
    email: null,
    comments: null,
    reCaptchaToken: null,
    siteKey: config.RECAPTCHA_SITEKEY,
    messages: '',
    buttonText: 'Submit',
  }),
  computed: {

  },
  mounted() {

  },
  methods: {
    onFormSubmit(e) {
      e.preventDefault();
      this.checkForm();
      if (!this.errors.length) {
        this.buttonText = 'Please wait ...';
        let data = {
          'mode': 'Contact',
          'company': this.company,
          'industry': this.industry,
          'country': this.country,
          'first_name': this.first_name,
          'last_name': this.last_name,
          'phone': this.phone,
          'email': this.email,
          'comments': this.comments,
          'reCaptchaToken': this.reCaptchaToken
        };
        axios.post('/core/contact.php', data, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          if (response.data.status === 'ok') {
            this.messages = "Thank you! We've received your enquiry.";
            this.buttonText = 'Submit';

            this.$gtag.event('contact_form');

            setTimeout(() => {
              this.messages = '';
              grecaptcha.reset()
            }, 5000);
          }
        }).catch((err) => {
          this.messages = 'Something went wrong. Please try to refresh the page and try again. If the error occurs again, please contact support.';
          this.buttonText = 'Submit';
        });
      }
    },
    checkForm(e) {
      this.errors = [];
      if (!this.company) {
        this.errors.push('Company name cannot be empty.');
      }
      if (!this.industry) {
        this.errors.push('Industry name cannot be empty.');
      }
      if (!this.country) {
        this.errors.push('Country cannot be empty.');
      }
      if (!this.first_name) {
        this.errors.push('First Name cannot be empty.');
      }
      if (!this.last_name) {
        this.errors.push('Last Name cannot be empty.');
      }
      if (!this.phone) {
        this.errors.push('Phone cannot be empty.');
      }
      if (!this.email) {
        this.errors.push('Email cannot be empty.');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Please enter valid email address.');
      }
      if (!this.comments) {
        this.errors.push('Comments cannot be empty.');
      }
      if (!this.reCaptchaToken) {
        this.errors.push('You need to solve reCaptcha Challenge');
      }
    },
    checkFormForEmptyFields() {
      let emptyFields = [];

      if (!this.company) {
        emptyFields.push('Company');
      } else {
        emptyFields = emptyFields.filter((value, idx, arr) => {
          return value !== 'Company';
        });
      }
      if (!this.industry) {
        emptyFields.push('Industry');
      } else {
        emptyFields = emptyFields.filter((value, idx, arr) => {
          return value !== 'Industry';
        });
      }
      if (!this.country) {
        emptyFields.push('Country');
      } else {
        emptyFields = emptyFields.filter((value, idx, arr) => {
          return value !== 'Country';
        });
      }
      if (!this.first_name) {
        emptyFields.push('First Name');
      } else {
        emptyFields = emptyFields.filter((value, idx, arr) => {
          return value !== 'First Name';
        });
      }
      if (!this.last_name) {
        emptyFields.push('Last Name');
      } else {
        emptyFields = emptyFields.filter((value, idx, arr) => {
          return value !== 'Last Name';
        });
      }
      if (!this.email) {
        emptyFields.push('Phone');
      } else {
        emptyFields = emptyFields.filter((value, idx, arr) => {
          return value !== 'Phone';
        });
      }
      if (!this.email) {
        emptyFields.push('Email');
      } else {
        emptyFields = emptyFields.filter((value, idx, arr) => {
          return value !== 'Email';
        });
      }
      if (!this.comments) {
        emptyFields.push('Comments');
      } else {
        emptyFields = emptyFields.filter((value, idx, arr) => {
          return value !== 'Comments';
        });
      }

      if (!emptyFields.length) {
        this.formIsFilled = true;
      }
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    onCaptchaVerify(reCaptchaToken) {
      this.reCaptchaToken = reCaptchaToken;
    },
    onCaptchaExpire() {
      this.$refs.recaptcha.reset();
      this.reCaptchaToken = null;
    },
  },
}
</script>