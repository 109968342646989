<template src='./DistributorFinder.html'></template>
<style scoped lang='scss' src='./DistributorFinder.scss'></style>

<script>
import { GChart } from 'vue-google-charts/legacy';
import axios from 'axios';
import config from './../../js/config';

export default {
  name: 'DistributorFinder',
  components: {
    GChart,
  },
  computed: {
    chartData: function() {
      let rows = [
        ['Code', 'Continent', 'Distributors'],
      ];

      this.continents.forEach((continent) => {
        let distributorsCount = continent.countries.reduce((sum, country) => {
          return sum + country.distributors.length;
        }, 0);
        rows.push([continent.code, continent.name, distributorsCount]);
      });

      return rows;
    },
    markersChartData: function () {
      let rows = [
        ['Country', 'Distributors']
      ];

      this.continents.forEach((continent) => {
        continent.countries.forEach((country) => {
          let distributorsCount = country.distributors.length;
          //@See https://github.com/google/google-visualization-issues/issues/1081
          if (country.name === 'New Zealand') {
            rows.push([
              { v: 'Queenstown, New Zealand', f: 'New Zealand' }, distributorsCount
            ]);
          } else {
            rows.push([country.name, distributorsCount]);
          }
        });
      });

      return rows;
    },
  },
  created() {
    axios.get(`${window.location.origin}/data/continents.json`)
        .then(response => {
          this.continents = response.data;
        });
  },
  mounted() {
    this.$nextTick(() => {

      let mapWrapper = this.$refs.mapWrapper;
      let continentsMap = this.$refs.mapContinents;
      let markersMap = this.$refs.mapMarkers;
      let wrapperWidth = mapWrapper.offsetWidth;
      markersMap.style.width = `${wrapperWidth}px`;
      continentsMap.style.width = `${wrapperWidth}px`;

      window.addEventListener('resize', () => {
        markersMap.style.width = `0px`;
        continentsMap.style.width = `0px`;
        markersMap.style.width = `${mapWrapper.offsetWidth}px`;
        continentsMap.style.width = `${mapWrapper.offsetWidth}px`;
      });
    });
  },
  data: () => ({
    chartSettings: {
      packages: ['geochart'],
      mapsApiKey: config.MAPS_API_KEY,
    },
    chartOptions: {
      region: 'world',
      resolution: 'countries',
      colorAxis: {
        colors: ['#92a0da', '#495594'],
      },
      datalessRegionColor: '#a1a1a1',
      responsive: true,
      width: '100%',
      legend: 'none'

    },
    markersChartOptions: {
      sizeAxis: { minValue: 0, maxValue: 100, minSize: 5 },
      colorAxis: {
        colors: ['#ED4238', '#ED4238']
      },
      displayMode: 'markers',
      backgroundColor: {fill: 'transparent', stroke: 'transparent', strokeWidth: 0},
      datalessRegionColor: 'transparent',
      responsive: true,
      width: '100%',
      legend: 'none',
      magnifyingGlass: {
        enable: false,
      }
    },
    continents: [],
  }),
  methods: {
    onContinentClick(selectedContinent) {
      selectedContinent.isActive = !selectedContinent.isActive;
      this.continents.map((continent) => {
        if (continent !== selectedContinent) {
          continent.isActive = false;
          continent.countries.forEach((country) => {
            country.isActive = false;
            country.distributors.forEach((distributor) => {
              distributor.isActive = false;
            });
          });
        }
      });
    },
    onCountryClick(selectedCountry) {
      selectedCountry.isActive = !selectedCountry.isActive;
    },
  },
}
</script>