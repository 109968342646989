<template src='./ProductFinder.html'></template>
<style scoped lang='scss' src='./ProductFinder.scss'></style>

<script>

export default {
  name: 'ProductFinder',
  computed: {
    maxAmpsValuesList: {
      get() {
        return this.getMaxAmpsValuesList(this.selectedNumberOfConductorsValue);
      },
      set(newValue) {
        return newValue;
      }
    },
    filteredProducts: function () {
      let productsList = this.products;
      if (this.selectedNumberOfConductorsValue && this.selectedNumberOfConductorsValue !== 'All') {
        productsList = productsList.filter((product) => {
          return product.numberOfConductors === this.selectedNumberOfConductorsValue;
        });
      }

      if (this.selectedMaxAmpsValue && this.selectedMaxAmpsValue !== 'All') {
        productsList = productsList.filter((product) => {
          return product.maxAmps === this.selectedMaxAmpsValue;
        });
      }

      return productsList;
    }
  },
  created() {
    this.selectedNumberOfConductorsValue = 'All';
    this.selectedMaxAmpsValue = 'All';
  },
  data: () => ({
    numberOfConductorsValuesList: ['All', '1','2','3','4','6+'],
    maxAmpsValuesList: ['All', '4', '10', '15', '20', '30', '250', '500'],
    selectedNumberOfConductorsValue: 'All',
    selectedMaxAmpsValue: 'All',
    products: [
      {
        numberOfConductors: '1',
        maxAmps: '4',
        name: 'Model 104',
        image: '/images/products/2023-Mercotac-104-web.jpg',
        page: '/html/104.html',
      },
      {
        numberOfConductors: '1',
        maxAmps: '4',
        name: 'Model 105',
        image: '/images/products/2023-Mercotac-105-web.jpg',
        page: '/html/105.html',
      },
      {
        numberOfConductors: '1',
        maxAmps: '10',
        name: 'Model 110',
        image: '/images/products/2023-Mercotac-110-web.jpg',
        page: '/html/104.html',
      },
      {
        numberOfConductors: '1',
        maxAmps: '10',
        name: 'Model 110T',
        image: '/images/products/2023-Mercotac-110T-web.jpg',
        page: '/html/105.html',
      },
      {
        numberOfConductors: '1',
        maxAmps: '30',
        name: 'Model 130',
        image: '/images/products/2023-Mercotac-130K-web.jpg',
        page: '/html/130.html',
      },
      {
        numberOfConductors: '1',
        maxAmps: '250',
        name: 'Model 1250',
        image: '/images/products/2023-Mercotac-1250-web.jpg',
        page: '/html/1250.html',
      },
      {
        numberOfConductors: '1',
        maxAmps: '500',
        name: 'Model 1500',
        image: '/images/products/2023-Mercotac-1500-web.jpg',
        page: '/html/1500.html',
      },
      {
        numberOfConductors: '2',
        maxAmps: '4',
        name: 'Model 205',
        image: '/images/products/2023-Mercotac-205-web.jpg',
        page: '/html/205.html',
      },
      {
        numberOfConductors: '2',
        maxAmps: '15',
        name: 'Model 215-2k',
        image: '/images/products/2023-Mercotac-215-2k-web.jpg',
        page: '/html/215.html',
      },
      {
        numberOfConductors: '2',
        maxAmps: '30',
        name: 'Model 230',
        image: '/images/products/2023-Mercotac-230-web.jpg',
        page: '/html/230.html',
      },
      {
        numberOfConductors: '2',
        maxAmps: '30',
        name: 'Model 235',
        image: '/images/products/2023-Mercotac-235-web.jpg',
        page: '/html/235.html',
      },
      {
        numberOfConductors: '2',
        maxAmps: '20',
        name: 'Versatac',
        image: '/images/products/2023-Mercotac-Laminar_Flange-web.jpg',
        page: '/html/versatac.html',
      },
      {
        numberOfConductors: '3',
        maxAmps: '4',
        name: 'Model 305',
        image: '/images/products/2023-Mercotac-305-web.jpg',
        page: '/html/305.html',
      },
      {
        numberOfConductors: '3',
        maxAmps: '30',
        name: 'Model 330',
        image: '/images/products/2023-Mercotac-330-web.jpg',
        page: '/html/330.html',
      },
      {
        numberOfConductors: '3',
        maxAmps: '30',
        name: 'Model 331',
        image: '/images/products/2023-Mercotac-331-web.jpg',
        page: '/html/331.html',
      },
      {
        numberOfConductors: '3',
        maxAmps: '30',
        name: 'Model 335',
        image: '/images/products/2023-Mercotac-335-web.jpg',
        page: '/html/335.html',
      },
      {
        numberOfConductors: '4',
        maxAmps: '30',
        name: 'Model 430',
        image: '/images/products/2023-Mercotac-430-web.jpg',
        page: '/html/430.html',
      },
      {
        numberOfConductors: '4',
        maxAmps: '30',
        name: 'Model 435',
        image: '/images/products/2023-Mercotac-435-web.jpg',
        page: '/html/435.html',
      },
      {
        numberOfConductors: '4',
        maxAmps: '20',
        name: 'Versatac',
        image: '/images/products/2023-Mercotac-Laminar_Flange-web.jpg',
        page: '/html/versatac.html',
      },
      {
        numberOfConductors: '6+',
        maxAmps: '30',
        name: 'Model 630',
        image: '/images/products/2023-Mercotac-630-web.jpg',
        page: '/html/630.html',
      },
      {
        numberOfConductors: '6+',
        maxAmps: '30',
        name: 'Model 830',
        image: '/images/products/2023-Mercotac-830-web.jpg',
        page: '/html/830.html',
      },
      {
        numberOfConductors: '6+',
        maxAmps: '20',
        name: 'Versatac',
        image: '/images/products/2023-Mercotac-Laminar_Flange-web.jpg',
        page: '/html/versatac.html',
      }
    ],
  }),
  methods: {
    onNumberOfConductorsValueChange(event) {
      this.selectedNumberOfConductorsValue = event.target.value;
      this.maxAmpsValuesList = this.getMaxAmpsValuesList(this.selectedNumberOfConductorsValue);
      if (!this.maxAmpsValuesList.includes(this.selectedMaxAmpsValue)) {
        this.selectedMaxAmpsValue = 'All';
      }
    },
    onMaxAmpsValueChange(event) {
      this.selectedMaxAmpsValue = event.target.value;
    },
    onClearFiltersClick() {
      this.selectedNumberOfConductorsValue = 'All';
      this.selectedMaxAmpsValue = 'All';
    },
    setSelectedNumberOfConductors(param) {
      this.selectedNumberOfConductorsValue = param;
      this.maxAmpsValuesList = this.getMaxAmpsValuesList(this.selectedNumberOfConductorsValue);
      if (!this.maxAmpsValuesList.includes(this.selectedMaxAmpsValue)) {
        this.selectedMaxAmpsValue = 'All';
      }
      this.$refs.resultsTitle.scrollIntoView({
        behavior: "smooth"
      });
    },
    getNumberOfConductorsValuesList(maxAmps) {
      let numberOfConductorsValuesList = [];
      switch (maxAmps) {
        case 'All':
          numberOfConductorsValuesList = ['All'];
          break;
      }
    },
    getMaxAmpsValuesList(numberOfConductors) {
      let maxAmpsValuesList = [];
      switch (numberOfConductors) {
        case 'All':
          maxAmpsValuesList = ['All'];
              break;
        case '1':
          maxAmpsValuesList = ['All', '4', '10', '30', '250', '500'];
          break;
        case '2':
          maxAmpsValuesList = ['All', '4', '15', '20', '30'];
          break;
        case '3':
          maxAmpsValuesList = ['All', '4', '30'];
          break;
        case '4':
          maxAmpsValuesList = ['All', '20', '30'];
          break;
        case '6+':
          maxAmpsValuesList = ['All', '20', '30'];
          break;
      }

      return maxAmpsValuesList;
    }
  },
}
</script>