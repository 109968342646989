import Header from './js/elements/header';
import Home from "./js/layouts/home";
import Footer from "./js/elements/footer";
import Accordion from 'accordion-js';
import videojs from "video.js";
import Vue from 'vue';
import ProductFinder from './components/ProductFinder/ProductFinder.vue';
import CadDownloader from "./components/CadDownloader/CadDownloader.vue";
import DistributorFinder from "./components/DistributorFinder/DistributorFinder.vue";
import ContactForm from "./components/ContactForm/ContactForm.vue";
import 'lazysizes';

export default class App {
    constructor() {
        document.addEventListener('DOMContentLoaded', () => {
            this.initialize();
        });
    }

    initialize = () => {
        this.header = new Header();
        this.footer = new Footer();
        new Home();
        let accordionContainers = document.querySelectorAll('.accordion-container');
        this.accordions = [];

        accordionContainers.forEach((accordion) => {
            this.accordions.push(new Accordion(accordion, {
                showMultiple: true
            }));
        });

        let videos = document.querySelectorAll('.video-js');
        videos.forEach((video) => {
            videojs(video);
        });

        document.addEventListener('DOMContentLoaded', function () {
            if (window.hideYTActivated) return;
            if (typeof YT === 'undefined') {
                let tag = document.createElement('script');
                tag.src = "https://www.youtube.com/iframe_api";
                let firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            }
            let onYouTubeIframeAPIReadyCallbacks = [];
            for (let playerWrap of document.querySelectorAll(".hytPlayerWrap")) {
                let playerFrame = playerWrap.querySelector("iframe");
                let onPlayerStateChange = function (event) {
                    if (event.data == YT.PlayerState.ENDED) {
                        playerWrap.classList.add("ended");
                    } else if (event.data == YT.PlayerState.PAUSED) {
                        playerWrap.classList.add("paused");
                    } else if (event.data == YT.PlayerState.PLAYING) {
                        playerWrap.classList.remove("ended");
                        playerWrap.classList.remove("paused");
                    }
                };
                let player;
                onYouTubeIframeAPIReadyCallbacks.push(function () {
                    player = new YT.Player(playerFrame, {events: {'onStateChange': onPlayerStateChange}});
                });
                playerWrap.addEventListener("click", function () {
                    let playerState = player.getPlayerState();
                    console.log(playerState);
                    if (playerState == YT.PlayerState.ENDED) {
                        player.seekTo(0);
                    } else if (playerState == YT.PlayerState.PAUSED) {
                        player.playVideo();
                    }
                });
            }
            window.onYouTubeIframeAPIReady = function () {
                for (let callback of onYouTubeIframeAPIReadyCallbacks) {
                    callback();
                }
            };
            window.hideYTActivated = true;
        });

        let url = new URL(window.location.href);
        let searchParams = url.searchParams;
        let targetAccordionId = searchParams.get('targetAccordionId');
        let targetAccordionItemId = searchParams.get('targetAccordionItemId');
        if (this.accordions.length > 0 && targetAccordionId) {
            let targetAccordion = this.accordions[targetAccordionId];
            if (targetAccordion && targetAccordionItemId) {
                targetAccordion.open(targetAccordionItemId)
                let targetItem = accordionContainers[targetAccordionId].children.item(targetAccordionItemId);
                if (targetItem) {
                    targetItem.scrollIntoView();
                }
            }
        }

        if (document.getElementById('productFinder')) {
            let productFilter = new Vue({
                el: '#productFinder',
                data: {
                    Vue
                },
                render: h => h(ProductFinder)
            });
        }

        if (document.getElementById('cadDownloader')) {
            let cadDownloader = new Vue({
                el: '#cadDownloader',
                data: {
                    Vue
                },
                render: h => h(CadDownloader)
            });
        }

        if (document.getElementById('distributorFinder')) {
            let distributorFinder = new Vue({
                el: '#distributorFinder',
                data: {
                    Vue
                },
                render: h => h(DistributorFinder)
            });
        }

        if (document.getElementById('contactForm')) {
            let distributorFinder = new Vue({
                el: '#contactForm',
                data: {
                    Vue
                },
                render: h => h(ContactForm)
            });
        }
    };
}

window.App = new App();
window.$ = $;
window.jQuery = $;
