<template src='./CadDownloader.html'></template>
<style scoped lang='scss' src='./CadDownloader.scss'></style>

<script>
import {VueRecaptcha} from 'vue-recaptcha';
import axios from 'axios';
import config from './../../js/config';

export default {
  name: "CadDownloader",
  components: {
    VueRecaptcha
  },
  data: () => ({
    products: [
      {
        name: '104',
        image: '/images/products/2023-Mercotac-104-web.jpg',
      },
      {
        name: '105',
        image: '/images/products/2023-Mercotac-105-web.jpg',
      },
      {
        name: '110',
        image: '/images/products/2023-Mercotac-110-web.jpg',
      },
      {
        name: '110T',
        image: '/images/products/2023-Mercotac-105-web.jpg',
      },
      {
        name: '130',
        image: '/images/products/2023-Mercotac-130K-web.jpg',
      },
      {
        name: '205',
        image: '/images/products/2023-Mercotac-205-web.jpg',
      },
      {
        name: '215-2k',
        image: '/images/products/2023-Mercotac-215-2k-web.jpg',
      },
      {
        name: '230',
        image: '/images/products/2023-Mercotac-230-web.jpg',
      },
      {
        name: '235',
        image: '/images/products/2023-Mercotac-235-web.jpg',
      },
      {
        name: '305',
        image: '/images/products/2023-Mercotac-305-web.jpg',
      },
      {
        name: '330',
        image: '/images/products/2023-Mercotac-330-web.jpg',
      },
      {
        name: '331',
        image: '/images/products/2023-Mercotac-331-web.jpg',
      },
      {
        name: '335',
        image: '/images/products/2023-Mercotac-335-web.jpg',
      },
      {
        name: '430',
        image: '/images/products/2023-Mercotac-430-web.jpg',
      },
      {
        name: '435',
        image: '/images/products/2023-Mercotac-435-web.jpg',
      },
      {
        name: '630',
        image: '/images/products/2023-Mercotac-630-web.jpg',
      },
      {
        name: '830',
        image: '/images/products/2023-Mercotac-830-web.jpg',
      },
      {
        name: '1250',
        image: '/images/products/2023-Mercotac-1250-web.jpg',
      },
      {
        name: '1500',
        image: '/images/products/2023-Mercotac-1500-web.jpg',
      },
      {
        name: '1500-1',
        image: '/images/products/2023-Mercotac-1500-1-web.jpg',
      },
      {
        name: 'Versatac',
        image: '/images/products/Versatac_page_pics_angled_1.png'
      }
    ],
    modesList: [
      'Spec Sheet (.PDF)', 'CAD File'
    ],
    cadFormatsList: [
      {
        title: '3-D .X_T',
        extension: '.x_t'
      },
      {
        title: '3-D .STEP',
        extension: '.STEP'
      },
      {
        title: '3-D .SAT',
        extension: '.SAT'
      },
    ],
    selectedMode: 'CAD File',
    selectedProductModel: '104',
    selectedCadFormat: '3-D .X_T',
    formIsActive: false,
    formIsFilled: false,
    errors: [],
    name: null,
    email: null,
    company: null,
    country: null,
    comments: null,
    reCaptchaToken: null,
    siteKey: config.RECAPTCHA_SITEKEY,
    messages: '',
    buttonText: 'Submit',
    availableSpecs: ['430','104','110', '435', '630', '830', '335', '330', '331', '230', '235', '305', '215-2k', '205', '105', '110T', '130'],
  }),
  computed: {
    selectedProductModelObject: function () {
      return this.findProductByModelName(this.selectedProductModel);
    },
    selectedCadFormatObject: function () {
      return this.cadFormatsList.find((format) => {
        return format.title === this.selectedCadFormat;
      });
    }
  },
  mounted() {
    let url = new URL(window.location.href);
    let searchParams = url.searchParams;
    let targetProductModelName = searchParams.get('p');
    if (targetProductModelName && this.findProductByModelName(targetProductModelName)) {
      this.selectedProductModel = targetProductModelName;
    }

    let targetMode = searchParams.get('mode');
    if (targetMode) {
      let el = this.$refs.cadDownloader;
      let viewportOffset = el.getBoundingClientRect().top;
      window.scrollTo({
        top: viewportOffset - 400,
        behavior: 'smooth'
      })
      if (targetMode === 'specSheet') {
        this.selectedMode = 'Spec Sheet (.PDF)';
      } else {
        this.selectedMode = 'CAD File';
      }
    }
  },
  methods: {
    onProductModelChange(event) {
      this.selectedProductModel = event.target.value;
    },
    onSelectedModeChange(event) {
      let mode = event.target.value;
      if (mode === 'Spec Sheet (.PDF)') {
        this.formIsActive = false;
      }
    },
    findProductByModelName(modelName) {
      return this.products.find((product) => {
        return product.name === modelName;
      });
    },
    handleDownloadButtonClick() {
      if (this.selectedMode === 'Spec Sheet (.PDF)') {
        if(this.availableSpecs.includes(this.selectedProductModel)) {
          alert(`You are going to download Spec Sheet for product - ${this.selectedProductModel}`);
          this.downloadFile(`${window.location.origin}/data/specs/${this.selectedProductModel}.pdf`, `${this.selectedProductModel}.pdf`);
        } else {
          alert(`Coming Soon! Spec Sheet for product - ${this.selectedProductModel}`);
        }
      } else {
        this.toggleForm();
      }
    },
    toggleForm() {
      this.formIsActive = !this.formIsActive;
    },
    onFormSubmit(e) {
      e.preventDefault();

      let format, extension;

      switch (this.selectedCadFormat) {
        case '3-D .X_T':
          format = 'Parasolid_X_T';
          extension = 'x_t';
          break;
        case '3-D .SAT':
          format = 'Acis_SAT';
          extension = 'SAT';
          break;
        case '3-D .STEP':
          format = 'STEP';
          extension = 'STEP';
          break;
      }

      this.checkForm();
      if (!this.errors.length) {
        let data = {
          'mode': 'CAD',
          'name': this.name,
          'email': this.email,
          'company': this.company,
          'country': this.country,
          'comments': this.comments,
          'product': this.selectedProductModel,
          'format': this.selectedCadFormat,
          'reCaptchaToken': this.reCaptchaToken
        };
        this.buttonText = 'Please wait ...';
        axios.post('/core/cad.php', data, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          if (response.data.status === 'ok') {
            this.toggleForm();
            this.messages = "We've received your enquiry. Your download will begin shortly.";

            let format, extension;

            switch (this.selectedCadFormat) {
              case '3-D .X_T':
                format = 'Parasolid_X_T';
                extension = 'x_t';
                break;
              case '3-D .SAT':
                format = 'Acis_SAT';
                extension = 'SAT';
                break;
              case '3-D .STEP':
                format = 'STEP';
                extension = 'STEP';
                break;
            }

            this.downloadFile(`${window.location.origin}/data/cad/${format}/${this.selectedProductModel}.${extension}`, `${this.selectedProductModel}.${extension}`);
            console.log('CAD download started');
            console.log('CAD file URL: ' + `${window.location.origin}/data/cad/${format}/${this.selectedProductModel}.${extension}`);
            console.log('CAD file name: ' + `${this.selectedProductModel}.${extension}`);
            this.buttonText = 'Submit';
            setTimeout(() => {
              this.messages = '';
              grecaptcha.reset()
            }, 2000);
          }
        }).catch((err) => {
          this.toggleForm();
          this.messages = 'Something went wrong. Please try to refresh the page and try again. If the error occurs again, please contact support.';
          this.buttonText = 'Submit';
        });
      }
    },
    checkForm(e) {
      this.errors = [];

      if (!this.name) {
        this.errors.push('Name cannot be empty.');
      }
      if (!this.company) {
        this.errors.push('Company name cannot be empty.');
      }
      if (!this.country) {
        this.errors.push('Country cannot be empty.');
      }
      if (!this.email) {
        this.errors.push('Email cannot be empty.');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Please enter valid email address.');
      }
      if (!this.comments) {
        this.errors.push('Comments cannot be empty.');
      }
      if (!this.reCaptchaToken) {
        this.errors.push('You need to solve reCaptcha Challenge');
      }
    },
    checkFormForEmptyFields() {
      let emptyFields = [];

      if (!this.name) {
        emptyFields.push('Name');
      } else {
        emptyFields = emptyFields.filter((value, idx, arr) => {
          return value !== 'Name';
        });
      }
      if (!this.company) {
        emptyFields.push('Company');
      } else {
        emptyFields = emptyFields.filter((value, idx, arr) => {
          return value !== 'Company';
        });
      }
      if (!this.country) {
        emptyFields.push('Country');
      } else {
        emptyFields = emptyFields.filter((value, idx, arr) => {
          return value !== 'Country';
        });
      }
      if (!this.email) {
        emptyFields.push('Email');
      } else {
        emptyFields = emptyFields.filter((value, idx, arr) => {
          return value !== 'Email';
        });
      }
      if (!this.comments) {
        emptyFields.push('Comments');
      } else {
        emptyFields = emptyFields.filter((value, idx, arr) => {
          return value !== 'Comments';
        });
      }

      if (!emptyFields.length) {
        this.formIsFilled = true;
      }
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    onCaptchaVerify(reCaptchaToken) {
      this.reCaptchaToken = reCaptchaToken;
    },
    onCaptchaExpire() {
      this.$refs.recaptcha.reset();
      this.reCaptchaToken = null;
    },
    downloadFile(url, fileName) {
      fetch(url, {method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer'})
          .then(res => res.blob())
          .then(res => {
            const aElement = document.createElement('a');
            aElement.setAttribute('download', fileName);
            const href = URL.createObjectURL(res);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
          });
    }
  },
}
</script>