import $ from 'jquery';
import 'slicknav/jquery.slicknav';
import 'slicknav/scss/slicknav.scss';

export default class Header {
    constructor() {
        this.header = document.querySelector('.header');
        if (this.header) {
            this.initialize();
        }
    }

    initialize = () => {
        this.initializeSlicknav();
        this.initializeTranslator();
    };

    initializeSlicknav = () => {
        $('.header .navigation').slicknav({
            'label': '',
            'appendTo': '.header',
            'allowParentLinks': true,
            'removeClasses': true
        });
    }

    initializeTranslator = () => {
        let languageSelectorWrapper = this.header.querySelector('.js-language-selector-wrapper');
        let languageSelector = this.header.querySelector('.language-selector');
        let gtr = document.getElementById('gtr');
        let config = { attributes: true, childList: true, subtree: true };
        let observer = new MutationObserver(() => {
            let documentLang = document.documentElement.lang;
            let options = Array.from(languageSelector.options);
            let optionToSelect;

            switch (documentLang) {
                case 'en':
                    optionToSelect = options.find(item => item.dataset.lang === documentLang);
                    languageSelectorWrapper.classList.remove('header-bar__lang_es');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ja');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ko');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
                    languageSelectorWrapper.classList.remove('header-bar__lang_de');
                    languageSelectorWrapper.classList.remove('header-bar__lang_it');
                    languageSelectorWrapper.classList.remove('header-bar__lang_fr');
                    languageSelectorWrapper.classList.remove('header-bar__lang_pt');
                    languageSelectorWrapper.classList.add('header-bar__lang_en');
                    break;
                case 'es':
                    optionToSelect = options.find(item => item.dataset.lang === documentLang);
                    languageSelectorWrapper.classList.remove('header-bar__lang_en');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ja');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ko');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
                    languageSelectorWrapper.classList.remove('header-bar__lang_de');
                    languageSelectorWrapper.classList.remove('header-bar__lang_it');
                    languageSelectorWrapper.classList.remove('header-bar__lang_fr');
                    languageSelectorWrapper.classList.remove('header-bar__lang_pt');
                    languageSelectorWrapper.classList.add('header-bar__lang_es');
                    break;
                case 'ja':
                    optionToSelect = options.find(item => item.dataset.lang === documentLang);
                    languageSelectorWrapper.classList.remove('header-bar__lang_en');
                    languageSelectorWrapper.classList.remove('header-bar__lang_es');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ko');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
                    languageSelectorWrapper.classList.remove('header-bar__lang_de');
                    languageSelectorWrapper.classList.remove('header-bar__lang_it');
                    languageSelectorWrapper.classList.remove('header-bar__lang_fr');
                    languageSelectorWrapper.classList.remove('header-bar__lang_pt');
                    languageSelectorWrapper.classList.add('header-bar__lang_ja');
                    break;
                case 'ko':
                    optionToSelect = options.find(item => item.dataset.lang === documentLang);
                    languageSelectorWrapper.classList.remove('header-bar__lang_en');
                    languageSelectorWrapper.classList.remove('header-bar__lang_es');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ja');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
                    languageSelectorWrapper.classList.remove('header-bar__lang_de');
                    languageSelectorWrapper.classList.remove('header-bar__lang_it');
                    languageSelectorWrapper.classList.remove('header-bar__lang_fr');
                    languageSelectorWrapper.classList.remove('header-bar__lang_pt');
                    languageSelectorWrapper.classList.add('header-bar__lang_ko');
                    break;
                case 'zh-CN':
                    optionToSelect = options.find(item => item.dataset.lang === documentLang);
                    languageSelectorWrapper.classList.remove('header-bar__lang_en');
                    languageSelectorWrapper.classList.remove('header-bar__lang_es');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ja');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ko');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
                    languageSelectorWrapper.classList.remove('header-bar__lang_de');
                    languageSelectorWrapper.classList.remove('header-bar__lang_it');
                    languageSelectorWrapper.classList.remove('header-bar__lang_fr');
                    languageSelectorWrapper.classList.remove('header-bar__lang_pt');
                    languageSelectorWrapper.classList.add('header-bar__lang_zh-CN');
                    break;
                case 'zh-TW':
                    optionToSelect = options.find(item => item.dataset.lang === documentLang);
                    languageSelectorWrapper.classList.remove('header-bar__lang_en');
                    languageSelectorWrapper.classList.remove('header-bar__lang_es');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ja');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ko');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
                    languageSelectorWrapper.classList.remove('header-bar__lang_de');
                    languageSelectorWrapper.classList.remove('header-bar__lang_it');
                    languageSelectorWrapper.classList.remove('header-bar__lang_fr');
                    languageSelectorWrapper.classList.remove('header-bar__lang_pt');
                    languageSelectorWrapper.classList.add('header-bar__lang_zh-TW');
                    break;
                case 'de':
                    optionToSelect = options.find(item => item.dataset.lang === documentLang);
                    languageSelectorWrapper.classList.remove('header-bar__lang_en');
                    languageSelectorWrapper.classList.remove('header-bar__lang_es');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ja');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ko');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
                    languageSelectorWrapper.classList.remove('header-bar__lang_it');
                    languageSelectorWrapper.classList.remove('header-bar__lang_fr');
                    languageSelectorWrapper.classList.remove('header-bar__lang_pt');
                    languageSelectorWrapper.classList.add('header-bar__lang_de');
                    break;
                case 'it':
                    optionToSelect = options.find(item => item.dataset.lang === documentLang);
                    languageSelectorWrapper.classList.remove('header-bar__lang_en');
                    languageSelectorWrapper.classList.remove('header-bar__lang_es');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ja');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ko');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
                    languageSelectorWrapper.classList.remove('header-bar__lang_de');
                    languageSelectorWrapper.classList.remove('header-bar__lang_fr');
                    languageSelectorWrapper.classList.remove('header-bar__lang_pt');
                    languageSelectorWrapper.classList.add('header-bar__lang_it');
                    break;
                case 'fr':
                    optionToSelect = options.find(item => item.dataset.lang === documentLang);
                    languageSelectorWrapper.classList.remove('header-bar__lang_en');
                    languageSelectorWrapper.classList.remove('header-bar__lang_es');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ja');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ko');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
                    languageSelectorWrapper.classList.remove('header-bar__lang_de');
                    languageSelectorWrapper.classList.remove('header-bar__lang_it');
                    languageSelectorWrapper.classList.remove('header-bar__lang_pt');
                    languageSelectorWrapper.classList.add('header-bar__lang_fr');
                    break;
                case 'pt':
                    optionToSelect = options.find(item => item.dataset.lang === documentLang);
                    languageSelectorWrapper.classList.remove('header-bar__lang_en');
                    languageSelectorWrapper.classList.remove('header-bar__lang_es');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ja');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ko');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
                    languageSelectorWrapper.classList.remove('header-bar__lang_de');
                    languageSelectorWrapper.classList.remove('header-bar__lang_it');
                    languageSelectorWrapper.classList.remove('header-bar__lang_fr');
                    languageSelectorWrapper.classList.add('header-bar__lang_pt');
                    break;
            }
            optionToSelect.selected = true;
        });
        observer.observe(gtr, config);

        // gtr.addEventListener('DOMSubtreeModified', (e) => {
        //     let documentLang = document.documentElement.lang;
        //
        //     let options = Array.from(languageSelector.options);
        //     let optionToSelect;
        //
        //     switch (documentLang) {
        //         case 'en':
        //             optionToSelect = options.find(item => item.dataset.lang === documentLang);
        //             languageSelectorWrapper.classList.remove('header-bar__lang_es');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_ja');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_ko');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_de');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_it');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_fr');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_pt');
        //             languageSelectorWrapper.classList.add('header-bar__lang_en');
        //             break;
        //         case 'es':
        //             optionToSelect = options.find(item => item.dataset.lang === documentLang);
        //             languageSelectorWrapper.classList.remove('header-bar__lang_en');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_ja');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_ko');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_de');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_it');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_fr');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_pt');
        //             languageSelectorWrapper.classList.add('header-bar__lang_es');
        //             break;
        //         case 'ja':
        //             optionToSelect = options.find(item => item.dataset.lang === documentLang);
        //             languageSelectorWrapper.classList.remove('header-bar__lang_en');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_es');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_ko');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_de');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_it');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_fr');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_pt');
        //             languageSelectorWrapper.classList.add('header-bar__lang_ja');
        //             break;
        //         case 'ko':
        //             optionToSelect = options.find(item => item.dataset.lang === documentLang);
        //             languageSelectorWrapper.classList.remove('header-bar__lang_en');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_es');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_ja');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_de');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_it');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_fr');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_pt');
        //             languageSelectorWrapper.classList.add('header-bar__lang_ko');
        //             break;
        //         case 'zh-CN':
        //             optionToSelect = options.find(item => item.dataset.lang === documentLang);
        //             languageSelectorWrapper.classList.remove('header-bar__lang_en');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_es');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_ja');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_ko');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_de');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_it');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_fr');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_pt');
        //             languageSelectorWrapper.classList.add('header-bar__lang_zh-CN');
        //             break;
        //         case 'zh-TW':
        //             optionToSelect = options.find(item => item.dataset.lang === documentLang);
        //             languageSelectorWrapper.classList.remove('header-bar__lang_en');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_es');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_ja');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_ko');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_de');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_it');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_fr');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_pt');
        //             languageSelectorWrapper.classList.add('header-bar__lang_zh-TW');
        //             break;
        //         case 'de':
        //             optionToSelect = options.find(item => item.dataset.lang === documentLang);
        //             languageSelectorWrapper.classList.remove('header-bar__lang_en');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_es');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_ja');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_ko');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_it');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_fr');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_pt');
        //             languageSelectorWrapper.classList.add('header-bar__lang_de');
        //             break;
        //         case 'it':
        //             optionToSelect = options.find(item => item.dataset.lang === documentLang);
        //             languageSelectorWrapper.classList.remove('header-bar__lang_en');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_es');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_ja');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_ko');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_de');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_fr');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_pt');
        //             languageSelectorWrapper.classList.add('header-bar__lang_it');
        //             break;
        //         case 'fr':
        //             optionToSelect = options.find(item => item.dataset.lang === documentLang);
        //             languageSelectorWrapper.classList.remove('header-bar__lang_en');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_es');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_ja');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_ko');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_de');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_it');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_pt');
        //             languageSelectorWrapper.classList.add('header-bar__lang_fr');
        //             break;
        //         case 'pt':
        //             optionToSelect = options.find(item => item.dataset.lang === documentLang);
        //             languageSelectorWrapper.classList.remove('header-bar__lang_en');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_es');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_ja');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_ko');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_de');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_it');
        //             languageSelectorWrapper.classList.remove('header-bar__lang_fr');
        //             languageSelectorWrapper.classList.add('header-bar__lang_pt');
        //             break;
        //     }
        //     optionToSelect.selected = true;
        // });
        languageSelector.addEventListener('change', () => {
            let selectedOption = languageSelector.options[languageSelector.selectedIndex];
            let lang = selectedOption.dataset.lang;
            let gtrSelector = document.querySelector('.goog-te-combo');

            switch (lang) {
                case 'en':
                    languageSelectorWrapper.classList.remove('header-bar__lang_es');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ja');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ko');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
                    languageSelectorWrapper.classList.remove('header-bar__lang_de');
                    languageSelectorWrapper.classList.remove('header-bar__lang_it');
                    languageSelectorWrapper.classList.remove('header-bar__lang_fr');
                    languageSelectorWrapper.classList.remove('header-bar__lang_pt');
                    languageSelectorWrapper.classList.add('header-bar__lang_en');
                    break;
                case 'es':
                    languageSelectorWrapper.classList.remove('header-bar__lang_en');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ja');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ko');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
                    languageSelectorWrapper.classList.remove('header-bar__lang_de');
                    languageSelectorWrapper.classList.remove('header-bar__lang_it');
                    languageSelectorWrapper.classList.remove('header-bar__lang_fr');
                    languageSelectorWrapper.classList.remove('header-bar__lang_pt');
                    languageSelectorWrapper.classList.add('header-bar__lang_es');
                    break;
                case 'ja':
                    languageSelectorWrapper.classList.remove('header-bar__lang_en');
                    languageSelectorWrapper.classList.remove('header-bar__lang_es');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ko');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
                    languageSelectorWrapper.classList.remove('header-bar__lang_de');
                    languageSelectorWrapper.classList.remove('header-bar__lang_it');
                    languageSelectorWrapper.classList.remove('header-bar__lang_fr');
                    languageSelectorWrapper.classList.remove('header-bar__lang_pt');
                    languageSelectorWrapper.classList.add('header-bar__lang_ja');
                    break;
                case 'ko':
                    languageSelectorWrapper.classList.remove('header-bar__lang_en');
                    languageSelectorWrapper.classList.remove('header-bar__lang_es');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ja');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
                    languageSelectorWrapper.classList.remove('header-bar__lang_de');
                    languageSelectorWrapper.classList.remove('header-bar__lang_it');
                    languageSelectorWrapper.classList.remove('header-bar__lang_fr');
                    languageSelectorWrapper.classList.remove('header-bar__lang_pt');
                    languageSelectorWrapper.classList.add('header-bar__lang_ko');
                    break;
                case 'zh-CN':
                    languageSelectorWrapper.classList.remove('header-bar__lang_en');
                    languageSelectorWrapper.classList.remove('header-bar__lang_es');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ja');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ko');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
                    languageSelectorWrapper.classList.remove('header-bar__lang_de');
                    languageSelectorWrapper.classList.remove('header-bar__lang_it');
                    languageSelectorWrapper.classList.remove('header-bar__lang_fr');
                    languageSelectorWrapper.classList.remove('header-bar__lang_pt');
                    languageSelectorWrapper.classList.add('header-bar__lang_zh-CN');
                    break;
                case 'zh-TW':
                    languageSelectorWrapper.classList.remove('header-bar__lang_en');
                    languageSelectorWrapper.classList.remove('header-bar__lang_es');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ja');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ko');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
                    languageSelectorWrapper.classList.remove('header-bar__lang_de');
                    languageSelectorWrapper.classList.remove('header-bar__lang_it');
                    languageSelectorWrapper.classList.remove('header-bar__lang_fr');
                    languageSelectorWrapper.classList.remove('header-bar__lang_pt');
                    languageSelectorWrapper.classList.add('header-bar__lang_zh-TW');
                    break;
                case 'de':
                    languageSelectorWrapper.classList.remove('header-bar__lang_en');
                    languageSelectorWrapper.classList.remove('header-bar__lang_es');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ja');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ko');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
                    languageSelectorWrapper.classList.remove('header-bar__lang_it');
                    languageSelectorWrapper.classList.remove('header-bar__lang_fr');
                    languageSelectorWrapper.classList.remove('header-bar__lang_pt');
                    languageSelectorWrapper.classList.add('header-bar__lang_de');
                    break;
                case 'it':
                    languageSelectorWrapper.classList.remove('header-bar__lang_en');
                    languageSelectorWrapper.classList.remove('header-bar__lang_es');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ja');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ko');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
                    languageSelectorWrapper.classList.remove('header-bar__lang_de');
                    languageSelectorWrapper.classList.remove('header-bar__lang_fr');
                    languageSelectorWrapper.classList.remove('header-bar__lang_pt');
                    languageSelectorWrapper.classList.add('header-bar__lang_it');
                    break;
                case 'fr':
                    languageSelectorWrapper.classList.remove('header-bar__lang_en');
                    languageSelectorWrapper.classList.remove('header-bar__lang_es');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ja');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ko');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
                    languageSelectorWrapper.classList.remove('header-bar__lang_de');
                    languageSelectorWrapper.classList.remove('header-bar__lang_it');
                    languageSelectorWrapper.classList.remove('header-bar__lang_pt');
                    languageSelectorWrapper.classList.add('header-bar__lang_fr');
                    break;
                case 'pt':
                    languageSelectorWrapper.classList.remove('header-bar__lang_en');
                    languageSelectorWrapper.classList.remove('header-bar__lang_es');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ja');
                    languageSelectorWrapper.classList.remove('header-bar__lang_ko');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-CN');
                    languageSelectorWrapper.classList.remove('header-bar__lang_zh-TW');
                    languageSelectorWrapper.classList.remove('header-bar__lang_de');
                    languageSelectorWrapper.classList.remove('header-bar__lang_it');
                    languageSelectorWrapper.classList.remove('header-bar__lang_fr');
                    languageSelectorWrapper.classList.add('header-bar__lang_pt');
                    break;
            }
            gtrSelector.value = lang;
            gtrSelector.dispatchEvent(new Event('change'));
        });
    }
}
