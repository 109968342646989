import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination, Autoplay, Lazy } from 'swiper/core';
SwiperCore.use([Navigation, Pagination, Autoplay, Lazy]);

export default class Home {
    constructor() {
        this.page = document.querySelector('.page-template-home');
        if (this.page) {
            this.initialize();
            this.bindEvents();
        }
    }

    initialize = () => {
        let heroSection = this.page.querySelector('.hero');
        let heroSlider = heroSection.querySelector('.hero-slider');
        new Swiper(heroSlider, {
            preloadImages: false,
            lazy: true,
            slidesPerView: 1,
            autoplay: {
                delay: 5500,
                disableOnInteraction: false,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        let testimonialsSlider = this.page.querySelector('.testimonials-slider');
        new Swiper(testimonialsSlider, {
            preloadImages: false,
            lazy: true,
            slidesPerView: 1,
            loop: true,
            autoplay: {
                delay: 15000,
                disableOnInteraction: false,
            }
        });

        let contentSection = this.page.querySelector('.content');
        let contentSlider = contentSection.querySelector('.content-slider');
        new Swiper(contentSlider, {
            preloadImages: false,
            lazy: true,
            slidesPerView: 1,
            autoplay: {
                delay: 3500,
                disableOnInteraction: false,
            },
            pagination: {
                el: '.swiper-pagination',
                bulletClass: 'swiper-pagination__bullet',
                bulletActiveClass: 'swiper-pagination__bullet_active',
                clickable: true
            },
        });
    };

    bindEvents = () => {

    };
}